#resume{
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* width: 50%; */
    /* height: 90%; */
    /* width: 80%; */
    width: 100vw;
    margin-top: 0;
    margin-bottom: 10%;
}
