.infoBlock{
    position:absolute;
    top:50%;
    left:65%;
    transform:translate(-50%,-50%);
    animation: appear 1s ease-in-out forwards;
}

#infoNameText{
    font-size: 2vw;
    font-weight: 700;
    color: #fff;
    text-shadow: -1px 1px 1px rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 1em;
    text-align: center;
    width: 90%;
}

#descriptionText{
    font-size: 1.5vw;
}

@keyframes appear{
    0%{
        opacity:0;
    }
    100%{
        opacity:100;
    }
}

@keyframes disappear{
    0%{
        opacity:100;
    }
    100%{
        opacity:0;
    }
}