@import "https://fonts.googleapis.com/css?family=Raleway";

.Constructing{
  color:white;
  font-size: 5vw;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Raleway, sans-serif;
  transition: all 0.3s ease-in-out;
}

.containerMain {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: stretch;
  align-content: space-around;
  background-color: #fff;
}

.box {
  flex: 1;
  color: #fff;
  display: flex;
  align-content: center;
  align-items: center;
  flex-flow: wrap;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
}

.box:hover {
  flex-grow: 2;
  opacity: 70% !important;
}

.box h2 {
  color: #fff;
  margin: auto;
  font: 700 2vw/45px raleway;
  text-transform: uppercase;
  letter-spacing: 5px;
  text-shadow: -1px 1px 1px rgba(0, 0, 0, 0.5);
}
.box p {
  font: 100 1vw raleway;
  color: #ecf0f1;
  text-align: center;
  width: 90%;
  margin: auto;
  text-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.box:first-of-type {
  background: linear-gradient(
    to top,
    rgb(0, 0, 0),
    rgb(2, 112, 11)
  );
  /* url("../assets/letterPaper.jpg") no-repeat center;
  background-size: cover; */
}

.box:nth-of-type(2) {
  background: linear-gradient(to top, rgb(0, 0, 0), rgb(2, 125, 127));
  /* url("../assets/coding.jpg") no-repeat center;
  background-size: cover; */
}

.box:nth-of-type(3) {
  background: linear-gradient(
    to top,
    rgb(0, 0, 0),
    rgb(1, 38, 111)
  );
  /* url("../assets/project.jpg") no-repeat center;
  background-size: cover; */
}
.box:last-of-type {
  background: linear-gradient(to top, rgb(0, 0, 0), rgb(101, 2, 99));
  /* url("../assets/contact.jpg") no-repeat center;
  background-size: cover; */
}
@media screen and (max-width: 600px) {
  h2 {
    font-size: 30px !important;
  }
  p {
    display: none;
  }
  .container {
    display: block;
  }
  .box {
    display: flex;
    width: 100%;
    height: 25vh;
  }
}
