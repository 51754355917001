.leftElements{
    color: white;
    cursor: pointer;
    font: size 1.5vw !important;;
}

#ed{
    opacity: 0;
    animation: moveRightAppear 1s ease-in-out forwards;
}

#ex{
    opacity: 0;
    animation: moveRightAppear 1s ease-in-out 0.25s forwards;
}

#sk{
    opacity: 0;
    animation: moveRightAppear 1s ease-in-out 0.5s forwards;
}

#pr{
    opacity: 0;
    animation: moveRightAppear 1s ease-in-out 0.75s forwards;
}


@keyframes moveRightAppear {
    0% {
        transform: translateX(0vw);
        opacity:0;
    }
    100% {
        transform: translateX(12vw);
        opacity:100;
    }
}

